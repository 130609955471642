const OrderStatus = {
  1: {id:1, sort_order: 0, status: 'Pending', class: 'bg-danger', color: '#ea4949'},
  2: {id:2,sort_order: 1, status: 'Processing', class: 'bg-warning', color: '#e8b34b'},
  15: {id:15,sort_order: 2, status: 'Processed', class: 'bg-info', color: '#4dc0b5'},
  3: {id:3,sort_order: 3, status: 'Shipped', class: 'bg-success-light', color: '#4dc0b5'},
  5: {id:5,sort_order: 4, status: 'Complete', class: 'bg-success', color: '#8dd350'},
  7: {id:7,sort_order: 5, status: 'Cancelled', class: 'bg-danger', color: '#ea4949'},
  11: {id:11,sort_order: 6, status: 'Refunded', class: 'bg-danger', color: '#ea4949'}
};

const InvoiceStatus = [
  {id:0, status: 'Draft', sort_order: 0,class: 'bg-info'},
  {id:1, status: 'Pending', sort_order: 10,class: 'bg-info'},
  {id:2, status: 'Paid',  sort_order: 20,class: 'bg-info'},
];

const QuotationStatus = [
  {id:0, status: 'Draft', sort_order: 0,class: 'bg-info'},
  {id:1, status: 'In-process', sort_order: 10,class: 'bg-info'},
  {id:2, status: 'Accept',  sort_order: 20,class: 'bg-info'},
  {id:3, status: 'Reject',  sort_order: 30,class: 'bg-info'},
];

const configurations = {
  OrderStatus: OrderStatus,
  InvoiceStatus: InvoiceStatus,
  QuotationStatus: QuotationStatus,
  
  ProductSort: [
    {'key':'PNA','name':'Product Name A-Z'},
    {'key':'PND','name':'Product Name Z-A'},
    {'key':'CLTH','name':'Price Low to High'},
    {'key':'CHTL','name':'Price High to Low'},
  ],

  SortForProduct: [
    {'value':'PNA','label':'Product Name A-Z'},
    {'value':'PND','label':'Product Name Z-A'},
    {'value':'CLTH','label':'Price Low to High'},
    {'value':'CHTL','label':'Price High to Low'},
  ],

  SortForQuotation: [
    {'value':'PNA','label':'Product Name A-Z'},
    {'value':'PND','label':'Product Name Z-A'},
    {'value':'CLTH','label':'Price Low to High'},
    {'value':'CHTL','label':'Price High to Low'},
  ],

  FilterForProduct: [
    {'value':'only','label':'Only Composite', 'key': 'composite'},
    {'value':'hide','label':'Hide Composite', 'key': 'composite'},
  ],


  ChannelSort: [
    {'value':'CNA','label':'Channel Name A-Z'},
    {'value':'CND','label':'Channel Name Z-A'},
  ],

  OrderSort: [
    {'key':'ORDD','name':'Newest to Oldest'},
    {'key':'ORDA','name':'Oldest to Newest'},
    
  ],

  Sort: [
    {'value':'ORDD','label':'Newest to Oldest'},
    {'value':'ORDA','label':'Oldest to Newest'},
  ],

   OrderCustomization : {
    cubic_meter: { key: 'cubic_meter', class: 'bg-success', color: '#8dd350' },
    delivery_charge: { key: 'delivery_charge', class: 'bg-dark', color: '#495057' },
    oiling: { key: 'oiling', class: 'bg-secondary', color: '#5e72eb' },
    custom_services: { key: 'custom_services', class: 'bg-warning', color: '#e8b34b' },
    oiling_percentage: { key: 'oiling_percentage', class: 'bg-danger', color: '#4dc0b5' },
    edging: { key: 'edging', class: 'bg-info', color: '#a6deef' },
  },
  
  Currencies: [
    { currency_code: 'GBP', currency_symbol: '£', currency_locale: 'en-GB' },
    { currency_code: 'INR', currency_symbol: '₹', currency_locale: 'en-IN' },
  ],

	NotificationType: [
		{label: 'SMS', value: 'sms' },
		{label: 'Email', value: 'email' },
		// {label: 'Whatsapp', value: 'whatsapp' },
	],

  Units: 
    {symbol: 'm<sup>3</sup>' , text: 'cubic meter'}
  ,


  Months: [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ],

  // AMAZON Marketplace id
  MarketplaceId: [
    {'name': 'Canada', 'key': 'A2EUQ1WTGCTBG2'},
    {'name': 'United States of America', 'key': 'ATVPDKIKX0DER'},
    {'name': 'Mexico', 'key': 'A1AM78C64UM0Y8'},
    {'name': 'Brazil', 'key': 'A2Q3Y263D00KWC'},
    {'name': 'Spain', 'key': 'A1RKKUPIHCS9HS'},
    {'name': 'United Kingdom', 'key': 'A1F83G8C2ARO7P'},
    {'name': 'France', 'key': 'A13V1IB3VIYZZH'},
    {'name': 'Netherlands', 'key': 'A1805IZSGTT6HS'},
    {'name': 'Germany', 'key': 'A1PA6795UKMFR9'},
    {'name': 'Italy', 'key': 'APJ6JRA9NG5V4'},
    {'name': 'Sweden', 'key': 'A2NODRKZP88ZB9'},
    {'name': 'Poland', 'key': 'A1C3SOZRARQ6R3'},
    {'name': 'Turkey', 'key': 'A33AVAJ2PDY3EV'},
    {'name': 'United Arab Emirates', 'key': 'A2VIGQ35RCS4UG'},
    {'name': 'India', 'key': 'A21TJRUUN4KGV'},
    {'name': 'Singapore', 'key': 'A19VAU5U5O7RUS'},
    {'name': 'Australia', 'key': 'A39IBJ37TRP1C6'},
    {'name': 'Japan	', 'key': 'A1VC38T7YXB528'},
  ],

  IntegrationSort: [
    {'value':'INA','label':'Integration Name A-Z'},
    {'value':'IND','label':'Integration Name Z-A'},
  ],

  Dashboard:[
    {'key':'all'},
  ],
  
  OrderFilters: [
    {'name':'Status',type:1, data: OrderStatus},
    {'name':'Order Date',type:2},
    {'name':'Order Total',type:3},
    {'name':'Channel',type:4},
  ],

  InvoiceFilters: [
    {'name':'Status',type:1, data: InvoiceStatus},
    {'name':'Date',type:2},
    {'name':'Due Date',type:3},
    {'name':'Total',type:4},
  ],
  
  SalesAnalysisFilters: [
    { 'name': 'Date Range', type: 5},
    {'name':'Status',type:1, data: OrderStatus},
    { 'name': 'Category', type:6},
    { 'name': 'Channel', type: 4},
  ],

  RecurringCustomerAnalysis: [
    { 'name': 'Date Range', type: 5},
  ],
  SampleOrderAnalysis: [
    { 'name': 'Date Range', type: 5},
  ],

  NotificationOrderFilters: [
    {'name':'Status',type:1, data: OrderStatus},
    {'name':'Order Date',type:2},
    {'name':'Channel',type:4},
  ],

  Comparison: [
   'is','is not','More Than','Less Than'
  ],

  OrderTriggers: [
    { label: "Order Received", value: "order_received", name: "order_received" },
    { label: "Order Scheduled for delivery", value: "order_scheduled_for_delivery", name: "order_scheduled_for_delivery" },
    { label: "Order Out for delivery", value: "order_out_for_delivery", name: "order_out_for_delivery" },
    { label: "Order Delivered", value: "order_delivered", name: "order_delivered" },
  ],

  Timezones: [
    { value: 'America/Adak', name: '(GMT-10:00) America/Adak (Hawaii-Aleutian Standard Time)' },
    { value: 'America/Atka', name: '(GMT-10:00) America/Atka (Hawaii-Aleutian Standard Time)' },
    { value: 'America/Anchorage', name: '(GMT-9:00) America/Anchorage (Alaska Standard Time)' },
    { value: 'America/Juneau', name: '(GMT-9:00) America/Juneau (Alaska Standard Time)' },
    { value: 'America/Nome', name: '(GMT-9:00) America/Nome (Alaska Standard Time)' },
    { value: 'America/Yakutat', name: '(GMT-9:00) America/Yakutat (Alaska Standard Time)' },
    { value: 'America/Dawson', name: '(GMT-8:00) America/Dawson (Pacific Standard Time)' },
    { value: 'America/Ensenada', name: '(GMT-8:00) America/Ensenada (Pacific Standard Time)' },
    { value: 'America/Los_Angeles', name: '(GMT-8:00) America/Los_Angeles (Pacific Standard Time)' },
    { value: 'America/Tijuana', name: '(GMT-8:00) America/Tijuana (Pacific Standard Time)' },
    { value: 'America/Vancouver', name: '(GMT-8:00) America/Vancouver (Pacific Standard Time)' },
    { value: 'America/Whitehorse', name: '(GMT-8:00) America/Whitehorse (Pacific Standard Time)' },
    { value: 'Canada/Pacific', name: '(GMT-8:00) Canada/Pacific (Pacific Standard Time)' },
    { value: 'Canada/Yukon', name: '(GMT-8:00) Canada/Yukon (Pacific Standard Time)' },
    { value: 'Mexico/BajaNorte', name: '(GMT-8:00) Mexico/BajaNorte (Pacific Standard Time)' },
    { value: 'America/Boise', name: '(GMT-7:00) America/Boise (Mountain Standard Time)' },
    { value: 'America/Cambridge_Bay', name: '(GMT-7:00) America/Cambridge_Bay (Mountain Standard Time)' },
    { value: 'America/Chihuahua', name: '(GMT-7:00) America/Chihuahua (Mountain Standard Time)' },
    { value: 'America/Dawson_Creek', name: '(GMT-7:00) America/Dawson_Creek (Mountain Standard Time)' },
    { value: 'America/Denver', name: '(GMT-7:00) America/Denver (Mountain Standard Time)' },
    { value: 'America/Edmonton', name: '(GMT-7:00) America/Edmonton (Mountain Standard Time)' },
    { value: 'America/Hermosillo', name: '(GMT-7:00) America/Hermosillo (Mountain Standard Time)' },
    { value: 'America/Inuvik', name: '(GMT-7:00) America/Inuvik (Mountain Standard Time)' },
    { value: 'America/Mazatlan', name: '(GMT-7:00) America/Mazatlan (Mountain Standard Time)' },
    { value: 'America/Phoenix', name: '(GMT-7:00) America/Phoenix (Mountain Standard Time)' },
    { value: 'America/Shiprock', name: '(GMT-7:00) America/Shiprock (Mountain Standard Time)' },
    { value: 'America/Yellowknife', name: '(GMT-7:00) America/Yellowknife (Mountain Standard Time)' },
    { value: 'Canada/Mountain', name: '(GMT-7:00) Canada/Mountain (Mountain Standard Time)' },
    { value: 'Mexico/BajaSur', name: '(GMT-7:00) Mexico/BajaSur (Mountain Standard Time)' },
    { value: 'America/Belize', name: '(GMT-6:00) America/Belize (Central Standard Time)' },
    { value: 'America/Cancun', name: '(GMT-6:00) America/Cancun (Central Standard Time)' },
    { value: 'America/Chicago', name: '(GMT-6:00) America/Chicago (Central Standard Time)' },
    { value: 'America/Costa_Rica', name: '(GMT-6:00) America/Costa_Rica (Central Standard Time)' },
    { value: 'America/El_Salvador', name: '(GMT-6:00) America/El_Salvador (Central Standard Time)' },
    { value: 'America/Guatemala', name: '(GMT-6:00) America/Guatemala (Central Standard Time)' },
    { value: 'America/Knox_IN', name: '(GMT-6:00) America/Knox_IN (Central Standard Time)' },
    { value: 'America/Managua', name: '(GMT-6:00) America/Managua (Central Standard Time)' },
    { value: 'America/Menominee', name: '(GMT-6:00) America/Menominee (Central Standard Time)' },
    { value: 'America/Merida', name: '(GMT-6:00) America/Merida (Central Standard Time)' },
    { value: 'America/Mexico_City', name: '(GMT-6:00) America/Mexico_City (Central Standard Time)' },
    { value: 'America/Monterrey', name: '(GMT-6:00) America/Monterrey (Central Standard Time)' },
    { value: 'America/Rainy_River', name: '(GMT-6:00) America/Rainy_River (Central Standard Time)' },
    { value: 'America/Rankin_Inlet', name: '(GMT-6:00) America/Rankin_Inlet (Central Standard Time)' },
    { value: 'America/Regina', name: '(GMT-6:00) America/Regina (Central Standard Time)' },
    { value: 'America/Swift_Current', name: '(GMT-6:00) America/Swift_Current (Central Standard Time)' },
    { value: 'America/Tegucigalpa', name: '(GMT-6:00) America/Tegucigalpa (Central Standard Time)' },
    { value: 'America/Winnipeg', name: '(GMT-6:00) America/Winnipeg (Central Standard Time)' },
    { value: 'Canada/Central', name: '(GMT-6:00) Canada/Central (Central Standard Time)' },
    { value: 'Canada/East-Saskatchewan', name: '(GMT-6:00) Canada/East-Saskatchewan (Central Standard Time)' },
    { value: 'Canada/Saskatchewan', name: '(GMT-6:00) Canada/Saskatchewan (Central Standard Time)' },
    { value: 'Chile/EasterIsland', name: '(GMT-6:00) Chile/EasterIsland (Easter Is. Time)' },
    { value: 'Mexico/General', name: '(GMT-6:00) Mexico/General (Central Standard Time)' },
    { value: 'America/Atikokan', name: '(GMT-5:00) America/Atikokan (Eastern Standard Time)' },
    { value: 'America/Bogota', name: '(GMT-5:00) America/Bogota (Colombia Time)' },
    { value: 'America/Cayman', name: '(GMT-5:00) America/Cayman (Eastern Standard Time)' },
    { value: 'America/Coral_Harbour', name: '(GMT-5:00) America/Coral_Harbour (Eastern Standard Time)' },
    { value: 'America/Detroit', name: '(GMT-5:00) America/Detroit (Eastern Standard Time)' },
    { value: 'America/Fort_Wayne', name: '(GMT-5:00) America/Fort_Wayne (Eastern Standard Time)' },
    { value: 'America/Grand_Turk', name: '(GMT-5:00) America/Grand_Turk (Eastern Standard Time)' },
    { value: 'America/Guayaquil', name: '(GMT-5:00) America/Guayaquil (Ecuador Time)' },
    { value: 'America/Havana', name: '(GMT-5:00) America/Havana (Cuba Standard Time)' },
    { value: 'America/Indianapolis', name: '(GMT-5:00) America/Indianapolis (Eastern Standard Time)' },
    { value: 'America/Iqaluit', name: '(GMT-5:00) America/Iqaluit (Eastern Standard Time)' },
    { value: 'America/Jamaica', name: '(GMT-5:00) America/Jamaica (Eastern Standard Time)' },
    { value: 'America/Lima', name: '(GMT-5:00) America/Lima (Peru Time)' },
    { value: 'America/Louisville', name: '(GMT-5:00) America/Louisville (Eastern Standard Time)' },
    { value: 'America/Montreal', name: '(GMT-5:00) America/Montreal (Eastern Standard Time)' },
    { value: 'America/Nassau', name: '(GMT-5:00) America/Nassau (Eastern Standard Time)' },
    { value: 'America/New_York', name: '(GMT-5:00) America/New_York (Eastern Standard Time)' },
    { value: 'America/Nipigon', name: '(GMT-5:00) America/Nipigon (Eastern Standard Time)' },
    { value: 'America/Panama', name: '(GMT-5:00) America/Panama (Eastern Standard Time)' },
    { value: 'America/Pangnirtung', name: '(GMT-5:00) America/Pangnirtung (Eastern Standard Time)' },
    { value: 'America/Port-au-Prince', name: '(GMT-5:00) America/Port-au-Prince (Eastern Standard Time)' },
    { value: 'America/Resolute', name: '(GMT-5:00) America/Resolute (Eastern Standard Time)' },
    { value: 'America/Thunder_Bay', name: '(GMT-5:00) America/Thunder_Bay (Eastern Standard Time)' },
    { value: 'America/Toronto', name: '(GMT-5:00) America/Toronto (Eastern Standard Time)' },
    { value: 'Canada/Eastern', name: '(GMT-5:00) Canada/Eastern (Eastern Standard Time)' },
    { value: 'America/Caracas', name: '(GMT-4:-30) America/Caracas (Venezuela Time)' },
    { value: 'America/Anguilla', name: '(GMT-4:00) America/Anguilla (Atlantic Standard Time)' },
    { value: 'America/Antigua', name: '(GMT-4:00) America/Antigua (Atlantic Standard Time)' },
    { value: 'America/Aruba', name: '(GMT-4:00) America/Aruba (Atlantic Standard Time)' },
    { value: 'America/Asuncion', name: '(GMT-4:00) America/Asuncion (Paraguay Time)' },
    { value: 'America/Barbados', name: '(GMT-4:00) America/Barbados (Atlantic Standard Time)' },
    { value: 'America/Blanc-Sablon', name: '(GMT-4:00) America/Blanc-Sablon (Atlantic Standard Time)' },
    { value: 'America/Boa_Vista', name: '(GMT-4:00) America/Boa_Vista (Amazon Time)' },
    { value: 'America/Campo_Grande', name: '(GMT-4:00) America/Campo_Grande (Amazon Time)' },
    { value: 'America/Cuiaba', name: '(GMT-4:00) America/Cuiaba (Amazon Time)' },
    { value: 'America/Curacao', name: '(GMT-4:00) America/Curacao (Atlantic Standard Time)' },
    { value: 'America/Dominica', name: '(GMT-4:00) America/Dominica (Atlantic Standard Time)' },
    { value: 'America/Eirunepe', name: '(GMT-4:00) America/Eirunepe (Amazon Time)' },
    { value: 'America/Glace_Bay', name: '(GMT-4:00) America/Glace_Bay (Atlantic Standard Time)' },
    { value: 'America/Goose_Bay', name: '(GMT-4:00) America/Goose_Bay (Atlantic Standard Time)' },
    { value: 'America/Grenada', name: '(GMT-4:00) America/Grenada (Atlantic Standard Time)' },
    { value: 'America/Guadeloupe', name: '(GMT-4:00) America/Guadeloupe (Atlantic Standard Time)' },
    { value: 'America/Guyana', name: '(GMT-4:00) America/Guyana (Guyana Time)' },
    { value: 'America/Halifax', name: '(GMT-4:00) America/Halifax (Atlantic Standard Time)' },
    { value: 'America/La_Paz', name: '(GMT-4:00) America/La_Paz (Bolivia Time)' },
    { value: 'America/Manaus', name: '(GMT-4:00) America/Manaus (Amazon Time)' },
    { value: 'America/Marigot', name: '(GMT-4:00) America/Marigot (Atlantic Standard Time)' },
    { value: 'America/Martinique', name: '(GMT-4:00) America/Martinique (Atlantic Standard Time)' },
    { value: 'America/Moncton', name: '(GMT-4:00) America/Moncton (Atlantic Standard Time)' },
    { value: 'America/Montserrat', name: '(GMT-4:00) America/Montserrat (Atlantic Standard Time)' },
    { value: 'America/Port_of_Spain', name: '(GMT-4:00) America/Port_of_Spain (Atlantic Standard Time)' },
    { value: 'America/Porto_Acre', name: '(GMT-4:00) America/Porto_Acre (Amazon Time)' },
    { value: 'America/Porto_Velho', name: '(GMT-4:00) America/Porto_Velho (Amazon Time)' },
    { value: 'America/Puerto_Rico', name: '(GMT-4:00) America/Puerto_Rico (Atlantic Standard Time)' },
    { value: 'America/Rio_Branco', name: '(GMT-4:00) America/Rio_Branco (Amazon Time)' },
    { value: 'America/Santiago', name: '(GMT-4:00) America/Santiago (Chile Time)' },
    { value: 'America/Santo_Domingo', name: '(GMT-4:00) America/Santo_Domingo (Atlantic Standard Time)' },
    { value: 'America/St_Barthelemy', name: '(GMT-4:00) America/St_Barthelemy (Atlantic Standard Time)' },
    { value: 'America/St_Kitts', name: '(GMT-4:00) America/St_Kitts (Atlantic Standard Time)' },
    { value: 'America/St_Lucia', name: '(GMT-4:00) America/St_Lucia (Atlantic Standard Time)' },
    { value: 'America/St_Thomas', name: '(GMT-4:00) America/St_Thomas (Atlantic Standard Time)' },
    { value: 'America/St_Vincent', name: '(GMT-4:00) America/St_Vincent (Atlantic Standard Time)' },
    { value: 'America/Thule', name: '(GMT-4:00) America/Thule (Atlantic Standard Time)' },
    { value: 'America/Tortola', name: '(GMT-4:00) America/Tortola (Atlantic Standard Time)' },
    { value: 'America/Virgin', name: '(GMT-4:00) America/Virgin (Atlantic Standard Time)' },
    { value: 'Antarctica/Palmer', name: '(GMT-4:00) Antarctica/Palmer (Chile Time)' },
    { value: 'Atlantic/Bermuda', name: '(GMT-4:00) Atlantic/Bermuda (Atlantic Standard Time)' },
    { value: 'Atlantic/Stanley', name: '(GMT-4:00) Atlantic/Stanley (Falkland Is. Time)' },
    { value: 'Brazil/Acre', name: '(GMT-4:00) Brazil/Acre (Amazon Time)' },
    { value: 'Brazil/West', name: '(GMT-4:00) Brazil/West (Amazon Time)' },
    { value: 'Canada/Atlantic', name: '(GMT-4:00) Canada/Atlantic (Atlantic Standard Time)' },
    { value: 'Chile/Continental', name: '(GMT-4:00) Chile/Continental (Chile Time)' },
    { value: 'America/St_Johns', name: '(GMT-3:-30) America/St_Johns (Newfoundland Standard Time)' },
    { value: 'Canada/Newfoundland', name: '(GMT-3:-30) Canada/Newfoundland (Newfoundland Standard Time)' },
    { value: 'America/Araguaina', name: '(GMT-3:00) America/Araguaina (Brasilia Time)' },
    { value: 'America/Bahia', name: '(GMT-3:00) America/Bahia (Brasilia Time)' },
    { value: 'America/Belem', name: '(GMT-3:00) America/Belem (Brasilia Time)' },
    { value: 'America/Buenos_Aires', name: '(GMT-3:00) America/Buenos_Aires (Argentine Time)' },
    { value: 'America/Catamarca', name: '(GMT-3:00) America/Catamarca (Argentine Time)' },
    { value: 'America/Cayenne', name: '(GMT-3:00) America/Cayenne (French Guiana Time)' },
    { value: 'America/Cordoba', name: '(GMT-3:00) America/Cordoba (Argentine Time)' },
    { value: 'America/Fortaleza', name: '(GMT-3:00) America/Fortaleza (Brasilia Time)' },
    { value: 'America/Godthab', name: '(GMT-3:00) America/Godthab (Western Greenland Time)' },
    { value: 'America/Jujuy', name: '(GMT-3:00) America/Jujuy (Argentine Time)' },
    { value: 'America/Maceio', name: '(GMT-3:00) America/Maceio (Brasilia Time)' },
    { value: 'America/Mendoza', name: '(GMT-3:00) America/Mendoza (Argentine Time)' },
    { value: 'America/Miquelon', name: '(GMT-3:00) America/Miquelon (Pierre & Miquelon Standard Time)' },
    { value: 'America/Montevideo', name: '(GMT-3:00) America/Montevideo (Uruguay Time)' },
    { value: 'America/Paramaribo', name: '(GMT-3:00) America/Paramaribo (Suriname Time)' },
    { value: 'America/Recife', name: '(GMT-3:00) America/Recife (Brasilia Time)' },
    { value: 'America/Rosario', name: '(GMT-3:00) America/Rosario (Argentine Time)' },
    { value: 'America/Santarem', name: '(GMT-3:00) America/Santarem (Brasilia Time)' },
    { value: 'America/Sao_Paulo', name: '(GMT-3:00) America/Sao_Paulo (Brasilia Time)' },
    { value: 'Antarctica/Rothera', name: '(GMT-3:00) Antarctica/Rothera (Rothera Time)' },
    { value: 'Brazil/East', name: '(GMT-3:00) Brazil/East (Brasilia Time)' },
    { value: 'America/Noronha', name: '(GMT-2:00) America/Noronha (Fernando de Noronha Time)' },
    { value: 'Atlantic/South_Georgia', name: '(GMT-2:00) Atlantic/South_Georgia (South Georgia Standard Time)' },
    { value: 'Brazil/DeNoronha', name: '(GMT-2:00) Brazil/DeNoronha (Fernando de Noronha Time)' },
    { value: 'America/Scoresbysund', name: '(GMT-1:00) America/Scoresbysund (Eastern Greenland Time)' },
    { value: 'Atlantic/Azores', name: '(GMT-1:00) Atlantic/Azores (Azores Time)' },
    { value: 'Atlantic/Cape_Verde', name: '(GMT-1:00) Atlantic/Cape_Verde (Cape Verde Time)' },
    { value: 'Africa/Abidjan', name: '(GMT+0:00) Africa/Abidjan (Greenwich Mean Time)' },
    { value: 'Africa/Accra', name: '(GMT+0:00) Africa/Accra (Ghana Mean Time)' },
    { value: 'Africa/Bamako', name: '(GMT+0:00) Africa/Bamako (Greenwich Mean Time)' },
    { value: 'Africa/Banjul', name: '(GMT+0:00) Africa/Banjul (Greenwich Mean Time)' },
    { value: 'Africa/Bissau', name: '(GMT+0:00) Africa/Bissau (Greenwich Mean Time)' },
    { value: 'Africa/Casablanca', name: '(GMT+0:00) Africa/Casablanca (Western European Time)' },
    { value: 'Africa/Conakry', name: '(GMT+0:00) Africa/Conakry (Greenwich Mean Time)' },
    { value: 'Africa/Dakar', name: '(GMT+0:00) Africa/Dakar (Greenwich Mean Time)' },
    { value: 'Africa/El_Aaiun', name: '(GMT+0:00) Africa/El_Aaiun (Western European Time)' },
    { value: 'Africa/Freetown', name: '(GMT+0:00) Africa/Freetown (Greenwich Mean Time)' },
    { value: 'Africa/Lome', name: '(GMT+0:00) Africa/Lome (Greenwich Mean Time)' },
    { value: 'Africa/Monrovia', name: '(GMT+0:00) Africa/Monrovia (Greenwich Mean Time)' },
    { value: 'Africa/Nouakchott', name: '(GMT+0:00) Africa/Nouakchott (Greenwich Mean Time)' },
    { value: 'Africa/Ouagadougou', name: '(GMT+0:00) Africa/Ouagadougou (Greenwich Mean Time)' },
    { value: 'Africa/Sao_Tome', name: '(GMT+0:00) Africa/Sao_Tome (Greenwich Mean Time)' },
    { value: 'Africa/Timbuktu', name: '(GMT+0:00) Africa/Timbuktu (Greenwich Mean Time)' },
    { value: 'America/Danmarkshavn', name: '(GMT+0:00) America/Danmarkshavn (Greenwich Mean Time)' },
    { value: 'Atlantic/Canary', name: '(GMT+0:00) Atlantic/Canary (Western European Time)' },
    { value: 'Atlantic/Faeroe', name: '(GMT+0:00) Atlantic/Faeroe (Western European Time)' },
    { value: 'Atlantic/Faroe', name: '(GMT+0:00) Atlantic/Faroe (Western European Time)' },
    { value: 'Atlantic/Madeira', name: '(GMT+0:00) Atlantic/Madeira (Western European Time)' },
    { value: 'Atlantic/Reykjavik', name: '(GMT+0:00) Atlantic/Reykjavik (Greenwich Mean Time)' },
    { value: 'Atlantic/St_Helena', name: '(GMT+0:00) Atlantic/St_Helena (Greenwich Mean Time)' },
    { value: 'Europe/Belfast', name: '(GMT+0:00) Europe/Belfast (Greenwich Mean Time)' },
    { value: 'Europe/Dublin', name: '(GMT+0:00) Europe/Dublin (Greenwich Mean Time)' },
    { value: 'Europe/Guernsey', name: '(GMT+0:00) Europe/Guernsey (Greenwich Mean Time)' },
    { value: 'Europe/Isle_of_Man', name: '(GMT+0:00) Europe/Isle_of_Man (Greenwich Mean Time)' },
    { value: 'Europe/Jersey', name: '(GMT+0:00) Europe/Jersey (Greenwich Mean Time)' },
    { value: 'Europe/Lisbon', name: '(GMT+0:00) Europe/Lisbon (Western European Time)' },
    { value: 'Europe/London', name: '(GMT+0:00) Europe/London (Greenwich Mean Time)' },
    { value: 'Africa/Algiers', name: '(GMT+1:00) Africa/Algiers (Central European Time)' },
    { value: 'Africa/Bangui', name: '(GMT+1:00) Africa/Bangui (Western African Time)' },
    { value: 'Africa/Brazzaville', name: '(GMT+1:00) Africa/Brazzaville (Western African Time)' },
    { value: 'Africa/Ceuta', name: '(GMT+1:00) Africa/Ceuta (Central European Time)' },
    { value: 'Africa/Douala', name: '(GMT+1:00) Africa/Douala (Western African Time)' },
    { value: 'Africa/Kinshasa', name: '(GMT+1:00) Africa/Kinshasa (Western African Time)' },
    { value: 'Africa/Lagos', name: '(GMT+1:00) Africa/Lagos (Western African Time)' },
    { value: 'Africa/Libreville', name: '(GMT+1:00) Africa/Libreville (Western African Time)' },
    { value: 'Africa/Luanda', name: '(GMT+1:00) Africa/Luanda (Western African Time)' },
    { value: 'Africa/Malabo', name: '(GMT+1:00) Africa/Malabo (Western African Time)' },
    { value: 'Africa/Ndjamena', name: '(GMT+1:00) Africa/Ndjamena (Western African Time)' },
    { value: 'Africa/Niamey', name: '(GMT+1:00) Africa/Niamey (Western African Time)' },
    { value: 'Africa/Porto-Novo', name: '(GMT+1:00) Africa/Porto-Novo (Western African Time)' },
    { value: 'Africa/Tunis', name: '(GMT+1:00) Africa/Tunis (Central European Time)' },
    { value: 'Africa/Windhoek', name: '(GMT+1:00) Africa/Windhoek (Western African Time)' },
    { value: 'Arctic/Longyearbyen', name: '(GMT+1:00) Arctic/Longyearbyen (Central European Time)' },
    { value: 'Atlantic/Jan_Mayen', name: '(GMT+1:00) Atlantic/Jan_Mayen (Central European Time)' },
    { value: 'Europe/Amsterdam', name: '(GMT+1:00) Europe/Amsterdam (Central European Time)' },
    { value: 'Europe/Andorra', name: '(GMT+1:00) Europe/Andorra (Central European Time)' },
    { value: 'Europe/Belgrade', name: '(GMT+1:00) Europe/Belgrade (Central European Time)' },
    { value: 'Europe/Berlin', name: '(GMT+1:00) Europe/Berlin (Central European Time)' },
    { value: 'Europe/Bratislava', name: '(GMT+1:00) Europe/Bratislava (Central European Time)' },
    { value: 'Europe/Brussels', name: '(GMT+1:00) Europe/Brussels (Central European Time)' },
    { value: 'Europe/Budapest', name: '(GMT+1:00) Europe/Budapest (Central European Time)' },
    { value: 'Europe/Copenhagen', name: '(GMT+1:00) Europe/Copenhagen (Central European Time)' },
    { value: 'Europe/Gibraltar', name: '(GMT+1:00) Europe/Gibraltar (Central European Time)' },
    { value: 'Europe/Ljubljana', name: '(GMT+1:00) Europe/Ljubljana (Central European Time)' },
    { value: 'Europe/Luxembourg', name: '(GMT+1:00) Europe/Luxembourg (Central European Time)' },
    { value: 'Europe/Madrid', name: '(GMT+1:00) Europe/Madrid (Central European Time)' },
    { value: 'Europe/Malta', name: '(GMT+1:00) Europe/Malta (Central European Time)' },
    { value: 'Europe/Monaco', name: '(GMT+1:00) Europe/Monaco (Central European Time)' },
    { value: 'Europe/Oslo', name: '(GMT+1:00) Europe/Oslo (Central European Time)' },
    { value: 'Europe/Paris', name: '(GMT+1:00) Europe/Paris (Central European Time)' },
    { value: 'Europe/Podgorica', name: '(GMT+1:00) Europe/Podgorica (Central European Time)' },
    { value: 'Europe/Prague', name: '(GMT+1:00) Europe/Prague (Central European Time)' },
    { value: 'Europe/Rome', name: '(GMT+1:00) Europe/Rome (Central European Time)' },
    { value: 'Europe/San_Marino', name: '(GMT+1:00) Europe/San_Marino (Central European Time)' },
    { value: 'Europe/Sarajevo', name: '(GMT+1:00) Europe/Sarajevo (Central European Time)' },
    { value: 'Europe/Skopje', name: '(GMT+1:00) Europe/Skopje (Central European Time)' },
    { value: 'Europe/Stockholm', name: '(GMT+1:00) Europe/Stockholm (Central European Time)' },
    { value: 'Europe/Tirane', name: '(GMT+1:00) Europe/Tirane (Central European Time)' },
    { value: 'Europe/Vaduz', name: '(GMT+1:00) Europe/Vaduz (Central European Time)' },
    { value: 'Europe/Vatican', name: '(GMT+1:00) Europe/Vatican (Central European Time)' },
    { value: 'Europe/Vienna', name: '(GMT+1:00) Europe/Vienna (Central European Time)' },
    { value: 'Europe/Warsaw', name: '(GMT+1:00) Europe/Warsaw (Central European Time)' },
    { value: 'Europe/Zagreb', name: '(GMT+1:00) Europe/Zagreb (Central European Time)' },
    { value: 'Europe/Zurich', name: '(GMT+1:00) Europe/Zurich (Central European Time)' },
    { value: 'Africa/Blantyre', name: '(GMT+2:00) Africa/Blantyre (Central African Time)' },
    { value: 'Africa/Bujumbura', name: '(GMT+2:00) Africa/Bujumbura (Central African Time)' },
    { value: 'Africa/Cairo', name: '(GMT+2:00) Africa/Cairo (Eastern European Time)' },
    { value: 'Africa/Gaborone', name: '(GMT+2:00) Africa/Gaborone (Central African Time)' },
    { value: 'Africa/Harare', name: '(GMT+2:00) Africa/Harare (Central African Time)' },
    { value: 'Africa/Johannesburg', name: '(GMT+2:00) Africa/Johannesburg (South Africa Standard Time)' },
    { value: 'Africa/Kigali', name: '(GMT+2:00) Africa/Kigali (Central African Time)' },
    { value: 'Africa/Lubumbashi', name: '(GMT+2:00) Africa/Lubumbashi (Central African Time)' },
    { value: 'Africa/Lusaka', name: '(GMT+2:00) Africa/Lusaka (Central African Time)' },
    { value: 'Africa/Maputo', name: '(GMT+2:00) Africa/Maputo (Central African Time)' },
    { value: 'Africa/Maseru', name: '(GMT+2:00) Africa/Maseru (South Africa Standard Time)' },
    { value: 'Africa/Mbabane', name: '(GMT+2:00) Africa/Mbabane (South Africa Standard Time)' },
    { value: 'Africa/Tripoli', name: '(GMT+2:00) Africa/Tripoli (Eastern European Time)' },
    { value: 'Asia/Amman', name: '(GMT+2:00) Asia/Amman (Eastern European Time)' },
    { value: 'Asia/Beirut', name: '(GMT+2:00) Asia/Beirut (Eastern European Time)' },
    { value: 'Asia/Damascus', name: '(GMT+2:00) Asia/Damascus (Eastern European Time)' },
    { value: 'Asia/Gaza', name: '(GMT+2:00) Asia/Gaza (Eastern European Time)' },
    { value: 'Asia/Istanbul', name: '(GMT+2:00) Asia/Istanbul (Eastern European Time)' },
    { value: 'Asia/Jerusalem', name: '(GMT+2:00) Asia/Jerusalem (Israel Standard Time)' },
    { value: 'Asia/Nicosia', name: '(GMT+2:00) Asia/Nicosia (Eastern European Time)' },
    { value: 'Asia/Tel_Aviv', name: '(GMT+2:00) Asia/Tel_Aviv (Israel Standard Time)' },
    { value: 'Europe/Athens', name: '(GMT+2:00) Europe/Athens (Eastern European Time)' },
    { value: 'Europe/Bucharest', name: '(GMT+2:00) Europe/Bucharest (Eastern European Time)' },
    { value: 'Europe/Chisinau', name: '(GMT+2:00) Europe/Chisinau (Eastern European Time)' },
    { value: 'Europe/Helsinki', name: '(GMT+2:00) Europe/Helsinki (Eastern European Time)' },
    { value: 'Europe/Istanbul', name: '(GMT+2:00) Europe/Istanbul (Eastern European Time)' },
    { value: 'Europe/Kaliningrad', name: '(GMT+2:00) Europe/Kaliningrad (Eastern European Time)' },
    { value: 'Europe/Kiev', name: '(GMT+2:00) Europe/Kiev (Eastern European Time)' },
    { value: 'Europe/Mariehamn', name: '(GMT+2:00) Europe/Mariehamn (Eastern European Time)' },
    { value: 'Europe/Minsk', name: '(GMT+2:00) Europe/Minsk (Eastern European Time)' },
    { value: 'Europe/Nicosia', name: '(GMT+2:00) Europe/Nicosia (Eastern European Time)' },
    { value: 'Europe/Riga', name: '(GMT+2:00) Europe/Riga (Eastern European Time)' },
    { value: 'Europe/Simferopol', name: '(GMT+2:00) Europe/Simferopol (Eastern European Time)' },
    { value: 'Europe/Sofia', name: '(GMT+2:00) Europe/Sofia (Eastern European Time)' },
    { value: 'Europe/Tallinn', name: '(GMT+2:00) Europe/Tallinn (Eastern European Time)' },
    { value: 'Europe/Tiraspol', name: '(GMT+2:00) Europe/Tiraspol (Eastern European Time)' },
    { value: 'Europe/Uzhgorod', name: '(GMT+2:00) Europe/Uzhgorod (Eastern European Time)' },
    { value: 'Europe/Vilnius', name: '(GMT+2:00) Europe/Vilnius (Eastern European Time)' },
    { value: 'Europe/Zaporozhye', name: '(GMT+2:00) Europe/Zaporozhye (Eastern European Time)' },
    { value: 'Africa/Addis_Ababa', name: '(GMT+3:00) Africa/Addis_Ababa (Eastern African Time)' },
    { value: 'Africa/Asmara', name: '(GMT+3:00) Africa/Asmara (Eastern African Time)' },
    { value: 'Africa/Asmera', name: '(GMT+3:00) Africa/Asmera (Eastern African Time)' },
    { value: 'Africa/Dar_es_Salaam', name: '(GMT+3:00) Africa/Dar_es_Salaam (Eastern African Time)' },
    { value: 'Africa/Djibouti', name: '(GMT+3:00) Africa/Djibouti (Eastern African Time)' },
    { value: 'Africa/Kampala', name: '(GMT+3:00) Africa/Kampala (Eastern African Time)' },
    { value: 'Africa/Khartoum', name: '(GMT+3:00) Africa/Khartoum (Eastern African Time)' },
    { value: 'Africa/Mogadishu', name: '(GMT+3:00) Africa/Mogadishu (Eastern African Time)' },
    { value: 'Africa/Nairobi', name: '(GMT+3:00) Africa/Nairobi (Eastern African Time)' },
    { value: 'Antarctica/Syowa', name: '(GMT+3:00) Antarctica/Syowa (Syowa Time)' },
    { value: 'Asia/Aden', name: '(GMT+3:00) Asia/Aden (Arabia Standard Time)' },
    { value: 'Asia/Baghdad', name: '(GMT+3:00) Asia/Baghdad (Arabia Standard Time)' },
    { value: 'Asia/Bahrain', name: '(GMT+3:00) Asia/Bahrain (Arabia Standard Time)' },
    { value: 'Asia/Kuwait', name: '(GMT+3:00) Asia/Kuwait (Arabia Standard Time)' },
    { value: 'Asia/Qatar', name: '(GMT+3:00) Asia/Qatar (Arabia Standard Time)' },
    { value: 'Europe/Moscow', name: '(GMT+3:00) Europe/Moscow (Moscow Standard Time)' },
    { value: 'Europe/Volgograd', name: '(GMT+3:00) Europe/Volgograd (Volgograd Time)' },
    { value: 'Indian/Antananarivo', name: '(GMT+3:00) Indian/Antananarivo (Eastern African Time)' },
    { value: 'Indian/Comoro', name: '(GMT+3:00) Indian/Comoro (Eastern African Time)' },
    { value: 'Indian/Mayotte', name: '(GMT+3:00) Indian/Mayotte (Eastern African Time)' },
    { value: 'Asia/Tehran', name: '(GMT+3:30) Asia/Tehran (Iran Standard Time)' },
    { value: 'Asia/Baku', name: '(GMT+4:00) Asia/Baku (Azerbaijan Time)' },
    { value: 'Asia/Dubai', name: '(GMT+4:00) Asia/Dubai (Gulf Standard Time)' },
    { value: 'Asia/Muscat', name: '(GMT+4:00) Asia/Muscat (Gulf Standard Time)' },
    { value: 'Asia/Tbilisi', name: '(GMT+4:00) Asia/Tbilisi (Georgia Time)' },
    { value: 'Asia/Yerevan', name: '(GMT+4:00) Asia/Yerevan (Armenia Time)' },
    { value: 'Europe/Samara', name: '(GMT+4:00) Europe/Samara (Samara Time)' },
    { value: 'Indian/Mahe', name: '(GMT+4:00) Indian/Mahe (Seychelles Time)' },
    { value: 'Indian/Mauritius', name: '(GMT+4:00) Indian/Mauritius (Mauritius Time)' },
    { value: 'Indian/Reunion', name: '(GMT+4:00) Indian/Reunion (Reunion Time)' },
    { value: 'Asia/Kabul', name: '(GMT+4:30) Asia/Kabul (Afghanistan Time)' },
    { value: 'Asia/Aqtau', name: '(GMT+5:00) Asia/Aqtau (Aqtau Time)' },
    { value: 'Asia/Aqtobe', name: '(GMT+5:00) Asia/Aqtobe (Aqtobe Time)' },
    { value: 'Asia/Ashgabat', name: '(GMT+5:00) Asia/Ashgabat (Turkmenistan Time)' },
    { value: 'Asia/Ashkhabad', name: '(GMT+5:00) Asia/Ashkhabad (Turkmenistan Time)' },
    { value: 'Asia/Dushanbe', name: '(GMT+5:00) Asia/Dushanbe (Tajikistan Time)' },
    { value: 'Asia/Karachi', name: '(GMT+5:00) Asia/Karachi (Pakistan Time)' },
    { value: 'Asia/Oral', name: '(GMT+5:00) Asia/Oral (Oral Time)' },
    { value: 'Asia/Samarkand', name: '(GMT+5:00) Asia/Samarkand (Uzbekistan Time)' },
    { value: 'Asia/Tashkent', name: '(GMT+5:00) Asia/Tashkent (Uzbekistan Time)' },
    { value: 'Asia/Yekaterinburg', name: '(GMT+5:00) Asia/Yekaterinburg (Yekaterinburg Time)' },
    { value: 'Indian/Kerguelen', name: '(GMT+5:00) Indian/Kerguelen (French Southern & Antarctic Lands Time)' },
    { value: 'Indian/Maldives', name: '(GMT+5:00) Indian/Maldives (Maldives Time)' },
    { value: 'Asia/Calcutta', name: '(GMT+5:30) Asia/Calcutta (India Standard Time)' },
    { value: 'Asia/Colombo', name: '(GMT+5:30) Asia/Colombo (India Standard Time)' },
    { value: 'Asia/Kolkata', name: '(GMT+5:30) Asia/Kolkata (India Standard Time)' },
    { value: 'Asia/Katmandu', name: '(GMT+5:45) Asia/Katmandu (Nepal Time)' },
    { value: 'Antarctica/Mawson', name: '(GMT+6:00) Antarctica/Mawson (Mawson Time)' },
    { value: 'Antarctica/Vostok', name: '(GMT+6:00) Antarctica/Vostok (Vostok Time)' },
    { value: 'Asia/Almaty', name: '(GMT+6:00) Asia/Almaty (Alma-Ata Time)' },
    { value: 'Asia/Bishkek', name: '(GMT+6:00) Asia/Bishkek (Kirgizstan Time)' },
    { value: 'Asia/Dacca', name: '(GMT+6:00) Asia/Dacca (Bangladesh Time)' },
    { value: 'Asia/Dhaka', name: '(GMT+6:00) Asia/Dhaka (Bangladesh Time)' },
    { value: 'Asia/Novosibirsk', name: '(GMT+6:00) Asia/Novosibirsk (Novosibirsk Time)' },
    { value: 'Asia/Omsk', name: '(GMT+6:00) Asia/Omsk (Omsk Time)' },
    { value: 'Asia/Qyzylorda', name: '(GMT+6:00) Asia/Qyzylorda (Qyzylorda Time)' },
    { value: 'Asia/Thimbu', name: '(GMT+6:00) Asia/Thimbu (Bhutan Time)' },
    { value: 'Asia/Thimphu', name: '(GMT+6:00) Asia/Thimphu (Bhutan Time)' },
    { value: 'Indian/Chagos', name: '(GMT+6:00) Indian/Chagos (Indian Ocean Territory Time)' },
    { value: 'Asia/Rangoon', name: '(GMT+6:30) Asia/Rangoon (Myanmar Time)' },
    { value: 'Indian/Cocos', name: '(GMT+6:30) Indian/Cocos (Cocos Islands Time)' },
    { value: 'Antarctica/Davis', name: '(GMT+7:00) Antarctica/Davis (Davis Time)' },
    { value: 'Asia/Bangkok', name: '(GMT+7:00) Asia/Bangkok (Indochina Time)' },
    { value: 'Asia/Ho_Chi_Minh', name: '(GMT+7:00) Asia/Ho_Chi_Minh (Indochina Time)' },
    { value: 'Asia/Hovd', name: '(GMT+7:00) Asia/Hovd (Hovd Time)' },
    { value: 'Asia/Jakarta', name: '(GMT+7:00) Asia/Jakarta (West Indonesia Time)' },
    { value: 'Asia/Krasnoyarsk', name: '(GMT+7:00) Asia/Krasnoyarsk (Krasnoyarsk Time)' },
    { value: 'Asia/Phnom_Penh', name: '(GMT+7:00) Asia/Phnom_Penh (Indochina Time)' },
    { value: 'Asia/Pontianak', name: '(GMT+7:00) Asia/Pontianak (West Indonesia Time)' },
    { value: 'Asia/Saigon', name: '(GMT+7:00) Asia/Saigon (Indochina Time)' },
    { value: 'Asia/Vientiane', name: '(GMT+7:00) Asia/Vientiane (Indochina Time)' },
    { value: 'Indian/Christmas', name: '(GMT+7:00) Indian/Christmas (Christmas Island Time)' },
    { value: 'Antarctica/Casey', name: '(GMT+8:00) Antarctica/Casey (Western Standard Time (Australia))' },
    { value: 'Asia/Brunei', name: '(GMT+8:00) Asia/Brunei (Brunei Time)' },
    { value: 'Asia/Choibalsan', name: '(GMT+8:00) Asia/Choibalsan (Choibalsan Time)' },
    { value: 'Asia/Chongqing', name: '(GMT+8:00) Asia/Chongqing (China Standard Time)' },
    { value: 'Asia/Chungking', name: '(GMT+8:00) Asia/Chungking (China Standard Time)' },
    { value: 'Asia/Harbin', name: '(GMT+8:00) Asia/Harbin (China Standard Time)' },
    { value: 'Asia/Hong_Kong', name: '(GMT+8:00) Asia/Hong_Kong (Hong Kong Time)' },
    { value: 'Asia/Irkutsk', name: '(GMT+8:00) Asia/Irkutsk (Irkutsk Time)' },
    { value: 'Asia/Kashgar', name: '(GMT+8:00) Asia/Kashgar (China Standard Time)' },
    { value: 'Asia/Kuala_Lumpur', name: '(GMT+8:00) Asia/Kuala_Lumpur (Malaysia Time)' },
    { value: 'Asia/Kuching', name: '(GMT+8:00) Asia/Kuching (Malaysia Time)' },
    { value: 'Asia/Macao', name: '(GMT+8:00) Asia/Macao (China Standard Time)' },
    { value: 'Asia/Macau', name: '(GMT+8:00) Asia/Macau (China Standard Time)' },
    { value: 'Asia/Makassar', name: '(GMT+8:00) Asia/Makassar (Central Indonesia Time)' },
    { value: 'Asia/Manila', name: '(GMT+8:00) Asia/Manila (Philippines Time)' },
    { value: 'Asia/Shanghai', name: '(GMT+8:00) Asia/Shanghai (China Standard Time)' },
    { value: 'Asia/Singapore', name: '(GMT+8:00) Asia/Singapore (Singapore Time)' },
    { value: 'Asia/Taipei', name: '(GMT+8:00) Asia/Taipei (China Standard Time)' },
    { value: 'Asia/Ujung_Pandang', name: '(GMT+8:00) Asia/Ujung_Pandang (Central Indonesia Time)' },
    { value: 'Asia/Ulaanbaatar', name: '(GMT+8:00) Asia/Ulaanbaatar (Ulaanbaatar Time)' },
    { value: 'Asia/Ulan_Bator', name: '(GMT+8:00) Asia/Ulan_Bator (Ulaanbaatar Time)' },
    { value: 'Asia/Urumqi', name: '(GMT+8:00) Asia/Urumqi (China Standard Time)' },
    { value: 'Australia/Perth', name: '(GMT+8:00) Australia/Perth (Western Standard Time (Australia))' },
    { value: 'Australia/West', name: '(GMT+8:00) Australia/West (Western Standard Time (Australia))' },
    { value: 'Australia/Eucla', name: '(GMT+8:45) Australia/Eucla (Central Western Standard Time (Australia))' },
    { value: 'Asia/Dili', name: '(GMT+9:00) Asia/Dili (Timor-Leste Time)' },
    { value: 'Asia/Jayapura', name: '(GMT+9:00) Asia/Jayapura (East Indonesia Time)' },
    { value: 'Asia/Pyongyang', name: '(GMT+9:00) Asia/Pyongyang (Korea Standard Time)' },
    { value: 'Asia/Seoul', name: '(GMT+9:00) Asia/Seoul (Korea Standard Time)' },
    { value: 'Asia/Tokyo', name: '(GMT+9:00) Asia/Tokyo (Japan Standard Time)' },
    { value: 'Asia/Yakutsk', name: '(GMT+9:00) Asia/Yakutsk (Yakutsk Time)' },
    { value: 'Australia/Adelaide', name: '(GMT+9:30) Australia/Adelaide (Central Standard Time (South Australia))' },
    { value: 'Australia/Broken_Hill', name: '(GMT+9:30) Australia/Broken_Hill (Central Standard Time (South Australia/New South Wales))' },
    { value: 'Australia/Darwin', name: '(GMT+9:30) Australia/Darwin (Central Standard Time (Northern Territory))' },
    { value: 'Australia/North', name: '(GMT+9:30) Australia/North (Central Standard Time (Northern Territory))' },
    { value: 'Australia/South', name: '(GMT+9:30) Australia/South (Central Standard Time (South Australia))' },
    { value: 'Australia/Yancowinna', name: '(GMT+9:30) Australia/Yancowinna (Central Standard Time (South Australia/New South Wales))' },
    { value: 'Antarctica/DumontDUrville', name: '(GMT+10:00) Antarctica/DumontDUrville (Dumont-d\'Urville Time)' },
    { value: 'Asia/Sakhalin', name: '(GMT+10:00) Asia/Sakhalin (Sakhalin Time)' },
    { value: 'Asia/Vladivostok', name: '(GMT+10:00) Asia/Vladivostok (Vladivostok Time)' },
    { value: 'Australia/ACT', name: '(GMT+10:00) Australia/ACT (Eastern Standard Time (New South Wales))' },
    { value: 'Australia/Brisbane', name: '(GMT+10:00) Australia/Brisbane (Eastern Standard Time (Queensland))' },
    { value: 'Australia/Canberra', name: '(GMT+10:00) Australia/Canberra (Eastern Standard Time (New South Wales))' },
    { value: 'Australia/Currie', name: '(GMT+10:00) Australia/Currie (Eastern Standard Time (New South Wales))' },
    { value: 'Australia/Hobart', name: '(GMT+10:00) Australia/Hobart (Eastern Standard Time (Tasmania))' },
    { value: 'Australia/Lindeman', name: '(GMT+10:00) Australia/Lindeman (Eastern Standard Time (Queensland))' },
    { value: 'Australia/Melbourne', name: '(GMT+10:00) Australia/Melbourne (Eastern Standard Time (Victoria))' },
    { value: 'Australia/NSW', name: '(GMT+10:00) Australia/NSW (Eastern Standard Time (New South Wales))' },
    { value: 'Australia/Queensland', name: '(GMT+10:00) Australia/Queensland (Eastern Standard Time (Queensland))' },
    { value: 'Australia/Sydney', name: '(GMT+10:00) Australia/Sydney (Eastern Standard Time (New South Wales))' },
    { value: 'Australia/Tasmania', name: '(GMT+10:00) Australia/Tasmania (Eastern Standard Time (Tasmania))' },
    { value: 'Australia/Victoria', name: '(GMT+10:00) Australia/Victoria (Eastern Standard Time (Victoria))' },
    { value: 'Australia/LHI', name: '(GMT+10:30) Australia/LHI (Lord Howe Standard Time)' },
    { value: 'Australia/Lord_Howe', name: '(GMT+10:30) Australia/Lord_Howe (Lord Howe Standard Time)' },
    { value: 'Asia/Magadan', name: '(GMT+11:00) Asia/Magadan (Magadan Time)' },
    { value: 'Antarctica/McMurdo', name: '(GMT+12:00) Antarctica/McMurdo (New Zealand Standard Time)' },
    { value: 'Antarctica/South_Pole', name: '(GMT+12:00) Antarctica/South_Pole (New Zealand Standard Time)' },
    { value: 'Asia/Anadyr', name: '(GMT+12:00) Asia/Anadyr (Anadyr Time)' },
    { value: 'Asia/Kamchatka', name: '(GMT+12:00) Asia/Kamchatka (Petropavlovsk-Kamchatski Time)' }
  ],

};


export default configurations;