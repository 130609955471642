import React, { useState, useEffect } from "react";
import { UilFilter, UilAngleDown, UilTimes, UilCalendarAlt } from "@iconscout/react-unicons";
import Select from 'react-select';
import _, { isEmpty } from "lodash";
import moment from "moment";

import config from "../../../configurations";
import { notify } from "../../../scripts/notify";
import { DateRange } from "react-date-range";
import api from "../../../api";

export default function FilterComponent(props) {

  const [activeFilters, setActiveFilters] = useState(props.activeFilters || []);
  const [selectedRangeData, setSelectedRangeData] = useState(props.selectionRange || []);
  const [conditonOption, setConditonOption] = useState("AND");
  const [runFilter, setRunFilter] = useState([]);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [channelAll,setChannelAll] = useState([]);
  const [categoryAll,setCategoryAll] = useState([]);

  const toggleFilter = (_.differenceBy(props.filters, activeFilters, 'type'))

  const handleChange = (e, k, ranges) => {
    var previousFilter = activeFilters;
    var tempValue = e.target.value;
    previousFilter[k] = {
      ...previousFilter[k],
      [e.target.name]: tempValue
    }
    if (!props.sales){
      props.setCompare(prevPRops => ({
        ...prevPRops,
        "compare": tempValue
      }))
    }
    setActiveFilters(previousFilter)
    props.insertData(previousFilter);
    props.setCheckFilter(true);
  };

  // var channelAll = [];
  async function fetchMetaData() {
    await api.report.metadata().then(response => {
      let channelsData = response.data.Channels;
      let channels = channelsData.map(channels => ({
        value: channels.id, label: channels.channel_name
      }));
      setChannelAll(channels);
      let categoryData = response.data.Categories;
      let categories = categoryData.map(categories => ({
        value: categories.id, label: categories.name
      }));
      setCategoryAll(categories);
    });
    await setIsLoading(false)
  } 
  
  useEffect(() => {
    fetchMetaData();
  }, []);

  const handleSelect = (ranges, k) => {
    setSelectedRangeData(ranges.selection);
    props.handleSelect(ranges);
    var previousFilter = activeFilters;
    previousFilter[k] = {
      ...previousFilter[k],
      'compare': _.castArray(ranges.selection)
    }
    setActiveFilters(previousFilter);
    props.insertData(previousFilter);
    props.setCheckFilter(true);
  };

  const handeSelectChange = (e, k) => {
    var previousFilter = activeFilters;
    if (!Array.isArray(e)) {
      var e = [e];
    }
    var dropDownProducts = []
    if (Array.isArray(e)) {
      e.map(x => { dropDownProducts.push(x.value) })
    }
    dropDownProducts = dropDownProducts.join(',')
    previousFilter[k] = {
      ...previousFilter[k],
      'compare': dropDownProducts
    }
    if (!props.sales){
      props.setCompare(prevprops => ({
        ...prevprops,
        'compare': dropDownProducts
      }))
    }
    setActiveFilters(previousFilter)
    props.insertData(previousFilter);
    props.setCheckFilter(true);
  }

  const handleOnFilter = (e) => {
    e.preventDefault();
    var returnErrors = [];
    var tempFilters = activeFilters;
    tempFilters.map((v, k) => {
      if (!v.compare) returnErrors[k] = { compareError: "Empty" };
    });
    if (returnErrors.length) {
      setError(returnErrors);
      notify("failure", "Please check the filters");
    } else {
      if (tempFilters.length > 0) {
        props.runFilter();
        notify("success", "Filters Applied!");
      } else {
        notify("danger", "Please choose filters to apply...");
      }
    }
  };

  const addFilterDom = (e, filter) => {

    switch (filter.type) {
      case 1:
        filter = { ...filter, condition: 0, compare: "" }
        break;
      case 2:
        filter = { ...filter, condition: 0, compare: "" }
        break;
      case 3:
        filter = { ...filter, condition: 0, compare: "" }
        break;
      case 4:
        filter = { ...filter, condition: 0, compare: "" }
        break;
      case 5:
        filter = { ...filter, condition: 0, compare: "" }
        break;
      case 6:
        filter = { ...filter, condition: 0, compare: "" }
        break;
      default:
        break;
    }

    var tempError = error;
    tempError = { compareError: "" }

    setError(error.concat(tempError));

    setActiveFilters(activeFilters.concat(filter));
  }
  useEffect(() => {
    var object = _.find(props.filters, { 'name': 'Status' });
  }, []);

  function defaultValues( option , values) {
    return option.filter(element =>_.includes((values).split(",").map(Number), element.value))
  }

  var filtersAll = [];

    var object = _.find(props.filters, { 'name': 'Status' });
    if(object){
      for (let status in object.data) {
        filtersAll.push({ value: object.data[status].id, label: object.data[status].status })
      }
    }

  function handleRemove(i, type) {
    if (type == "5") {
      setSelectedRangeData(props.initial);
    }
    var values = [...activeFilters];
    values.splice(i, 1);
    var valuesErrors = [...error];
    valuesErrors.splice(i, 1);
    setError(valuesErrors);
    setActiveFilters(values);
    props.insertData(values);
    if (_.isEmpty(values)){
      props.setCheckFilter(true)
    }
  }

  useEffect(() => {
    setRunFilter(activeFilters.map((activeFilter, k) => returnType(activeFilter, k)));
  }, [activeFilters, selectedRangeData, channelAll]);

  const returnType = (v, k) => {
    var returnString = "";

    var dropDownCompare = (
      <div>

        <select key={activeFilters[k].condition} name="condition" className="form-select form-select-sm" onChange={e => handleChange(e, k)} defaultValue={activeFilters[k].condition} >
          {config.Comparison.map((val, key) =>
          (
            ([1, 4, 5, 6].includes(v.type) && [0, 1].includes(key)) ? (<option key={"drop" + key} value={key}>
              {val}
            </option>) :
              ([2, 3].includes(v.type)) ? <option key={"drop" + key} value={key}>
                {val}
              </option> : null
          )
          )}
        </select>

      </div>
    );

    let removeButton = (
      <UilTimes width="16" className="text-danger" onClick={() => handleRemove(k, v.type)} />
    );

    let condition = k === 0 ? "Where" :
      (k >= 2) ? (
        <select onChange={e => setConditonOption(e.target.value)} className="form-select form-select-sm" name="comparison" disabled>
          <option val={conditonOption}>{conditonOption}</option>
        </select>
      ) : (
        <select onChange={e => setConditonOption(e.target.value)} className="form-select form-select-sm" name="comparison" disabled>
          <option val="AND">AND</option>
          <option val="OR">OR</option>
        </select>
      )
    switch (v.type) {
      case 1:
        var comparison = v.compare.split(',');
        var filters = [];
        for (let status in filters.data) {
          if (comparison.includes(status))
            filters.push({ value: status, label: filters.data[status].status })
        }
        returnString = (
          <div className="d-flex mb-3 align-items-center justify-content-between" key={"activedir" + k}>
            {props.sales ? null : <div className="me-2">{condition}</div>}
            <div className="me-2">{v.name}</div>
            <div className="me-2">{dropDownCompare}</div>
            <div className="me-2 status-select-wrap">
              <Select
                name="compare" 
                key={"status-select" + k}
                className="status-select" 
                closeMenuOnSelect={false} 
                isMulti={true} 
                isSearchable={true} 
                onChange={e => handeSelectChange(e, k)}
                options={filtersAll}
                defaultValue= {defaultValues(filtersAll, activeFilters[k].compare )}
                />
            </div>
            <div>{removeButton}</div>
          </div>
        );
        break;
      case 2:
        returnString = (
          <div className="d-flex mb-3 align-items-center justify-content-between" key={"activedir" + k}>
            <div className="me-2">
              {condition}
            </div>
            <div className="me-2">{v.name}</div>
            <div className="me-2">{dropDownCompare}</div>
            <div className="me-2">
              <input 
                type="date" 
                placeholder="Date" 
                className="form-control form-control-sm" 
                name="compare" 
                onChange={e => handleChange(e, k)}
                defaultValue={v.compare} 
              />
            </div>
            <div>{removeButton}</div>
          </div>
        );
        break;
      case 3:
        returnString = (
          <div className="d-flex mb-3 align-items-center justify-content-between" key={"activedir" + k}>
            <div className="me-2">
              {condition}
            </div>
            <div className="me-2">{v.name}</div>
            <div className="me-2">{dropDownCompare}</div>
            <div className="me-2">
              <span className='d-none'> {v.compare}</span>
              <input 
                type="text" 
                placeholder="Amount" 
                className="form-control form-control-sm" name="compare" 
                onChange={e => handleChange(e, k)}
                defaultValue={v.compare}
              />
            </div>
            <div>
              {removeButton}
            </div>
          </div>
        );
        break;
      case 4:
        returnString = (
          <div className="d-flex mb-3 align-items-center justify-content-between" key={"activedir" + k}>
            {props.sales ? null : <div className="me-2">{condition}</div>}
            <div className="me-2">{v.name}</div>
            <div className="me-2">{dropDownCompare}</div>
            {!_.isEmpty(channelAll) ? (
            <div className="me-2 status-select-wrap">
              <Select
                name="channel-compare"
                key={"channel-select" + k}
                className="status-select"
                closeMenuOnSelect={true}
                isMulti={true}
                isSearchable={true}
                onChange={(e) => handeSelectChange(e, k)}
                options={channelAll}
                defaultValue= {defaultValues(channelAll, activeFilters[k].compare )}
              />
            </div>
            ):(null)}
            <div>{removeButton}</div>
          </div>
        );
        break;
      case 5:
        returnString = (
          <div className="d-flex mb-3 align-items-center justify-content-between" key={"activedir" + k}>

            <div className="me-2">{v.name}</div>
            <div className="me-2">
              <div className="dropdown">
                <button className="btn bg-lighter px-3" type="button" id="dashboard-range" data-bs-auto-close="outside" data-bs-toggle="dropdown" aria-expanded="false" >
                  <UilCalendarAlt width="15" className="me-2 text-secondary" />
                  {moment(selectedRangeData.startDate).format('D MMMM')} - {moment(selectedRangeData.endDate).format('D MMMM')} <UilAngleDown width="16" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dashboard-range" >
                  <DateRange 
                    name='dateRange' 
                    ranges={[selectedRangeData]} 
                    onChange={e => handleSelect(e, k)} 
                    maxDate={moment().toDate()} 
                  />
                </div>
              </div>
            </div>
            <div>
              {removeButton}
            </div>
          </div>
        );
        break;
      case 6:
        returnString = (
          <div className="d-flex mb-3 align-items-center justify-content-between" key={"activedir" + k}>
            <div className="me-2">{v.name}</div>
            <div className="me-2">{dropDownCompare}</div>
            <div className="me-2 status-select-wrap">
              <Select
                key={"category-select" + k}
                name="compare"
                className="status-select"
                closeMenuOnSelect={true}
                isMulti={true}
                isSearchable={true}
                onChange={e => handeSelectChange(e, k)}
                options={categoryAll}
                defaultValue= {defaultValues(categoryAll, activeFilters[k].compare )}
                />
            </div>
            <div>{removeButton}</div>
          </div>
        );
        break;
      default:
        break;
    }
    return returnString;
  };

  return (
    <div className="dropdown filter-dropdown me-3">
      <button className="btn bg-lighter px-3" type="button" id="filter" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" 
      disabled={_.isEmpty(channelAll) ? true : false} >
        <UilFilter width="16" className="text-secondary" /> Filters  <small>{activeFilters.length > 0 && "(" + activeFilters.length + ")"}</small><UilAngleDown width="16" />
      </button>
      <div className={`dropdown-menu dropdown-menu-end full-width`} aria-labelledby="filter" >
        <div className="pt-2 px-3 mb-3">
          {runFilter.length ? runFilter : (
            <div>No Active Filters</div>
          )}
        </div>
        <div className="d-flex justify-content-end px-3">
          {!_.isEmpty(toggleFilter) ? (
            <>
              <button className='btn px-3 text-secondary' type="button" id="addfilter" data-bs-toggle="dropdown" data-bs-auto-close="inside" aria-expanded="false" >
                + Add Filter
              </button>
              <ul className="dropdown-menu" aria-labelledby="addfilter" >
                {toggleFilter.map((filter, index) => (
                  <li key={index}>
                    <button className="dropdown-item" onClick={event => addFilterDom(event, filter)}>
                      {filter.name}
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )
            : (null)}

          <button className="btn btn-secondary" onClick={handleOnFilter} >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
